// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-content-tsx": () => import("./../../../src/pages/content.tsx" /* webpackChunkName: "component---src-pages-content-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-recommendations-tsx": () => import("./../../../src/pages/recommendations.tsx" /* webpackChunkName: "component---src-pages-recommendations-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-templates-content-tsx": () => import("./../../../src/templates/content.tsx" /* webpackChunkName: "component---src-templates-content-tsx" */),
  "component---src-templates-recommendations-tsx": () => import("./../../../src/templates/recommendations.tsx" /* webpackChunkName: "component---src-templates-recommendations-tsx" */)
}

