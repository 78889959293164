/* eslint-disable import/named */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import { DataProvider } from './src/components/Common/DataContext.tsx'

export const wrapRootElement = ({ element }) => <DataProvider>{element}</DataProvider>
