/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useState } from 'react'

type ContextProps = {
  step: number
  handleStep: (num: number) => void
  topicsParam: Array<Topic>
  handleTopicsParam: (topics: Array<Topic>) => void
  moneyParam: number
  timeParam: number
  engagementParam: number
  handleMoneyParam: (num: number) => void
  handleTimeParam: (num: number) => void
  handleEngagementParam: (num: number) => void
  handleGlobalResource: (num: number) => void
  globalResource: number
}

type Topic = 'culture' | 'hiring' | 'balance' | 'empowerment' | 'all'
const allTopics: Array<Topic> = ['culture', 'hiring', 'balance', 'empowerment', 'all']
const emptyTopics: Array<Topic> = []

const DataContext = React.createContext<ContextProps>({
  handleStep: () => null,
  step: 0,
  topicsParam: [],
  handleTopicsParam: () => null,
  moneyParam: 5,
  timeParam: 5,
  engagementParam: 5,
  handleMoneyParam: () => null,
  handleTimeParam: () => null,
  handleEngagementParam: () => null,
  handleGlobalResource: () => null,
  globalResource: 3
})

const DataProvider: React.FC = ({ children }) => {
  const [step, setStep] = React.useState(0)
  const handleStep = (num: number) => setStep(num)
  const [topicsParam, setTopicsParam] = React.useState(emptyTopics)
  const handleTopicsParam = (values: Array<Topic>) => {
    const handledValues = values.includes('all') && !topicsParam.includes('all') ? allTopics.map(elem => elem) : values
    setTopicsParam(handledValues)
  }
  const [moneyParam, setMoneyParam] = useState(5)
  const [timeParam, setTimeParam] = useState(5)
  const [engagementParam, setEngagementParam] = useState(5)
  const [globalResource, setGlobalResource] = useState(3)

  const handleGlobalResource = (globalValue: number) => {
    const detailValue = globalValue * 2 - 1
    setGlobalResource(globalValue)
    setEngagementParam(detailValue)
    setTimeParam(detailValue)
    setMoneyParam(detailValue)
  }

  const handleMoneyParam = (value: number) => {
    setMoneyParam(value)
  }

  const handleTimeParam = (value: number) => {
    setTimeParam(value)
  }

  const handleEngagementParam = (value: number) => {
    setEngagementParam(value)
  }

  return (
    <DataContext.Provider
      value={{
        step,
        handleStep,
        topicsParam,
        handleTopicsParam,
        moneyParam,
        timeParam,
        engagementParam,
        handleMoneyParam,
        handleTimeParam,
        handleEngagementParam,
        globalResource,
        handleGlobalResource
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export { DataProvider }
export default DataContext
